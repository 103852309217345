import Stats from 'stats-js';
import config from "./config";

if (config.stats) {
    var stats = new Stats();
    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild(stats.dom);

    var animate = function () {
        stats.begin();
        // monitored code goes here
        stats.end();
        requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
}

