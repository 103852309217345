/*global THREE, THREEx */

// TODO: make these imports work (https://github.com/jeromeetienne/AR.js/issues/428)
// import * as THREE from 'three';
// import 'script-loader!./ar';
import 'three/examples/js/loaders/GLTFLoader.js';
import '../styles/index.scss';
import config from './config';
import './stats';

// init renderer
var renderer = new THREE.WebGLRenderer(config.renderer);
renderer.setClearColor(new THREE.Color('lightgrey'), 0);
renderer.setSize(640, 640);
renderer.gammaOutput = true;
renderer.gammaFactor = 2.2;
renderer.domElement.style.position = 'absolute';
renderer.domElement.style.top = '0px';
renderer.domElement.style.left = '0px';
document.body.appendChild(renderer.domElement);

// array of functions for the rendering loop
var onRenderFcts = [];

// init scene and camera
var scene = new THREE.Scene();

//////////////////////////////////////////////////////////////////////////////////
//		Initialize a basic camera
//////////////////////////////////////////////////////////////////////////////////

// Create a camera
var camera = new THREE.Camera();
scene.add(camera);



// audio

var listener = new THREE.AudioListener();
camera.add(listener);
var sound = new THREE.Audio(listener);
// load a sound and set it as the Audio object's buffer
var audioLoader = new THREE.AudioLoader();
audioLoader.load('./public/audio.mp3', function (buffer) {
    sound.setBuffer(buffer);
    sound.setLoop(true);
    sound.setVolume(0.5);
    // sound.play();
});

////////////////////////////////////////////////////////////////////////////////
//          handle arToolkitSource
////////////////////////////////////////////////////////////////////////////////

var arToolkitSource = new THREEx.ArToolkitSource(config.toolkitSource);

arToolkitSource.init(function onReady() {
    onResize();
});

// handle resize
window.addEventListener('resize', function () {
    onResize();
});
function onResize() {
    arToolkitSource.onResizeElement();
    arToolkitSource.copyElementSizeTo(renderer.domElement);
    if (arToolkitContext.arController !== null) {
        arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas);
    }
}
////////////////////////////////////////////////////////////////////////////////
//          initialize arToolkitContext
////////////////////////////////////////////////////////////////////////////////

// create atToolkitContext
var arToolkitContext = new THREEx.ArToolkitContext(config.toolkitContext);
// initialize it
arToolkitContext.init(function onCompleted() {
    // copy projection matrix to camera
    camera.projectionMatrix.copy(arToolkitContext.getProjectionMatrix());
});

var markerVisible = false;

onRenderFcts.push(function () {
    if (arToolkitSource.ready === false) return;

    arToolkitContext.update(arToolkitSource.domElement);

    // update scene.visible if the marker is seen
    scene.visible = camera.visible;
    camera.visible ? cameraVisible() : cameraInvisible();
});

function cameraVisible() {
    if (markerVisible) {
        return;
    }
    window.console.log('marker is visible');
    if (!sound.isPlaying) {
        sound.play();
    }
    return markerVisible = true;
}
function cameraInvisible() {
    if (!markerVisible) {
        return;
    }
    if (sound.isPlaying) {
        sound.pause();
    }
    return markerVisible = false;

}

////////////////////////////////////////////////////////////////////////////////
//          Create a ArMarkerControls
////////////////////////////////////////////////////////////////////////////////

// init controls for camera
new THREEx.ArMarkerControls(arToolkitContext, camera, config.markerControls);
scene.visible = false;

var light = new THREE.AmbientLight(0xFFFFFF, 0.2); // soft white light
scene.add(light);

var light2 = new THREE.DirectionalLight(0xFFFFFF, 2, 5);
light2.position.set(-2, 2.8, 0.7);
scene.add(light2);

var light3 = new THREE.DirectionalLight(0xfeffd2, 3, 5);
light3.position.set(1, 1.8, -0.7);
scene.add(light3);

var loader = new THREE.GLTFLoader();
var mixer1 = undefined;
// var mixer2 = undefined;

loader.load(
    './public/scene.glb',
    (gltf) => {
        window.console.log(gltf);
        gltf.animations; // Array<THREE.AnimationClip>
        gltf.scene; // THREE.Scene
        gltf.scenes; // Array<THREE.Scene>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object
        gltf.scene.scale.set(.2, .2, .2); // scale here
        // gltf.scene.position.set(0, 0, 0.3);

        mixer1 = new THREE.AnimationMixer(gltf.scene);
        gltf.animations.forEach((clip) => {
            mixer1.clipAction(clip).play();
        });

        scene.add(gltf.scene);
    },
    // called while loading is progressing
    (xhr) => {
        window.console.info((xhr.loaded / xhr.total * 100) + '% loaded');
    },
    // called when loading has errors
    (error) => {
        window.console.warn('An error happened: ', error);
    }
);

//////////////////////////////////////////////////////////////////////////////////
//		render the whole thing on the page
//////////////////////////////////////////////////////////////////////////////////

// render the scene
onRenderFcts.push(function () {
    renderer.render(scene, camera);
});

var clock = new THREE.Clock();
var lastTimeMsec = null;
requestAnimationFrame(function animate(nowMsec) {
    requestAnimationFrame(animate);
    var delta = clock.getDelta();
    if (mixer1) { mixer1.update(delta); }
    // if (mixer2) { mixer2.update(delta); }
    lastTimeMsec = lastTimeMsec || nowMsec - 1000 / 60;
    var deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
    lastTimeMsec = nowMsec;
    onRenderFcts.forEach(function (onRenderFct) {
        onRenderFct(deltaMsec / 1000, nowMsec / 1000);
    });
});
