/*global THREEx */

// common config file
THREEx.ArToolkitContext.baseURL = './';

var config = {
    stats: false,
    renderer: {
        antialias: true,
        alpha: true
    },
    markerControls: {
        // https://github.com/jeromeetienne/AR.js/tree/master/three.js#threexarmarkercontrols
        type: 'pattern',
        patternUrl: THREEx.ArToolkitContext.baseURL + './public/marker.txt',
        changeMatrixMode: 'cameraTransformMatrix',
        smooth: true,
        smoothCount: 5,
        smoothTolerance: 0.01,
        smoothThreshold: 50
    },
    toolkitContext: {
        // https://github.com/jeromeetienne/AR.js/tree/master/three.js#threexartoolkitcontext
        cameraParametersUrl: THREEx.ArToolkitContext.baseURL + './public/camera_para.dat',
        detectionMode: 'mono',
        patternRatio: 0.5,
        canvasWidth: 640,
        canvasHeight: 480
    },
    toolkitSource: {
        // https://github.com/jeromeetienne/AR.js/tree/master/three.js#threexartoolkitsource
        sourceType: 'webcam',
        sourceWidth: 640,
        sourceHeight: 480,
        // resolution displayed for the source 
        displayWidth: 640,
        displayHeight: 480
    }
};
window.console.info('current config', config);

export default config;